import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { faCircleExclamation } from '@awesome.me/kit-dc19441bb1/icons/sharp/light'
import React from 'react'
import { useTranslations } from 'next-intl'

export function CheckoutErrorMessage(props: { errorMessage: string | undefined }) {
  const t = useTranslations('CheckOut.PaymentError')
  if (!props.errorMessage) {
    return <div className="my-4 h-12" />
  }

  return (
    <div className="my-4 flex min-h-12 items-start justify-center gap-2 text-red-500">
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <FontAwesomeIcon icon={faCircleExclamation} className="my-0.5 h-5" />
      {t(props.errorMessage)}
    </div>
  )
}
