import React, { MouseEvent, MutableRefObject } from 'react'
import Button from '@/components/common/button/Button'
import { ButtonType } from '@/components/common/button/ButtonType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { faLockKeyhole } from '@awesome.me/kit-dc19441bb1/icons/sharp/solid'
import { useTranslations } from 'next-intl'

export function PayNowButton(props: {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  buttonBlockedRef: MutableRefObject<boolean>
  disabled: boolean
}) {
  const t = useTranslations('CheckOut.Payment')

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (props.buttonBlockedRef.current) {
      event.preventDefault()
      event.stopPropagation()

      return
    }

    console.log('Button clicked')

    props.buttonBlockedRef.current = true
    props.onClick?.(event)
  }

  return (
    <Button
      buttonType={ButtonType.PRIMARY}
      className="flex w-full items-center py-2 text-primary-complement sm:w-auto"
      onClick={handleClick}
      disabled={props.disabled}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <FontAwesomeIcon icon={faLockKeyhole} className="h-4" />
      <div className="font-bolder mx-12 flex-grow text-center text-2xl">
        {t('payNowButtonLabel')}
      </div>
    </Button>
  )
}
