import { twMerge } from 'tailwind-merge'

export function Heading1(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
    children: string
    className?: string
  },
) {
  return (
    <h2
      {...props}
      className={twMerge('text-4xl font-bold text-stone-900 md:text-5xl', props.className)}
    >
      {props.children}
    </h2>
  )
}

export function Heading2(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
    children: string
    className?: string
  },
) {
  return (
    <h2
      {...props}
      className={twMerge('text-3xl font-bold text-stone-900 md:text-4xl', props.className)}
    >
      {props.children}
    </h2>
  )
}

export function Heading3(props: { children: string; className?: string }) {
  return (
    <h3 className={twMerge('text-xl font-bold text-stone-900 md:text-2xl', props.className)}>
      {props.children}
    </h3>
  )
}
