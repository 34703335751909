import { Locale, PaymentMethod } from '@mollie/api-client'
import { Locale as FitLocale } from '@/lib/sharedConstants'
import { FitReisenPaymentCode } from '@/types/Booking'

export type MolliePaymentMethod = PaymentMethod | 'blik' | 'twint' | 'klarna'

export type MolliePaymentMethodCodes = Exclude<
  FitReisenPaymentCode,
  FitReisenPaymentCode.Invoice | FitReisenPaymentCode.DirectDebit
>

export const CODE_TO_PAYMENT: Record<MolliePaymentMethodCodes, MolliePaymentMethod> = {
  [FitReisenPaymentCode.CreditCard]: PaymentMethod.creditcard,
  [FitReisenPaymentCode.Paypal]: PaymentMethod.paypal,
  [FitReisenPaymentCode.Sofort]: PaymentMethod.sofort,
  [FitReisenPaymentCode.Przelewy24]: PaymentMethod.przelewy24,
  [FitReisenPaymentCode.Blik]: 'blik',
  [FitReisenPaymentCode.Ideal]: PaymentMethod.ideal,
  [FitReisenPaymentCode.Eps]: PaymentMethod.eps,
  [FitReisenPaymentCode.ApplePay]: PaymentMethod.applepay,
  [FitReisenPaymentCode.Twint]: 'twint',
  [FitReisenPaymentCode.Klarna]: 'klarna',
  [FitReisenPaymentCode.Bancontact]: PaymentMethod.bancontact,
}

export const NON_MOLLIE_PAYMENT_METHOD_CODES = ['RG', 'LS']

export const PAYMENT_TO_CODE: Record<string, MolliePaymentMethodCodes> = {
  // directdebit: 'RG',
  creditcard: FitReisenPaymentCode.CreditCard,
  paypal: FitReisenPaymentCode.Paypal,
  sofort: FitReisenPaymentCode.Sofort,
  przelewy24: FitReisenPaymentCode.Przelewy24,
  blik: FitReisenPaymentCode.Blik,
  ideal: FitReisenPaymentCode.Ideal,
  eps: FitReisenPaymentCode.Eps,
  applepay: FitReisenPaymentCode.ApplePay,
  twint: FitReisenPaymentCode.Twint,
  klarna: FitReisenPaymentCode.Klarna,
  bancontact: FitReisenPaymentCode.Bancontact,
}

export const MOLLIE_PAYMENT_METHOD_CODES: MolliePaymentMethodCodes[] = [
  FitReisenPaymentCode.CreditCard,
  FitReisenPaymentCode.Paypal,
  FitReisenPaymentCode.Sofort,
  FitReisenPaymentCode.Przelewy24,
  FitReisenPaymentCode.Blik,
  FitReisenPaymentCode.Ideal,
  FitReisenPaymentCode.Eps,
  FitReisenPaymentCode.ApplePay,
  FitReisenPaymentCode.Twint,
  FitReisenPaymentCode.Klarna,
  FitReisenPaymentCode.Bancontact,
]

export const LOCALE_MAPPING: Record<FitLocale, Locale | 'en_GB'> = {
  [FitLocale.DE]: Locale.de_DE,
  [FitLocale.EN]: 'en_GB',
  [FitLocale.FR]: Locale.fr_FR,
  [FitLocale.ES]: Locale.es_ES,
  [FitLocale.IT]: Locale.it_IT,
  [FitLocale.PL]: Locale.pl_PL,
  [FitLocale.SV]: Locale.sv_SE,
  [FitLocale.NL]: Locale.nl_NL,
}

export const LEGACY_PAYMENT_METHODS_MAPPING: Record<string, MolliePaymentMethod> = {
  directdebit: PaymentMethod.directdebit,
  creditcard: PaymentMethod.creditcard,
  amex: PaymentMethod.creditcard,
  sofortbanking: PaymentMethod.sofort,
  sofort: PaymentMethod.sofort,
  paypal: PaymentMethod.paypal,
  ideal: PaymentMethod.ideal,
  p24: PaymentMethod.przelewy24,
  blik: 'blik',
  eps: PaymentMethod.eps,
  applepay: PaymentMethod.applepay,
  twint: 'twint',
  klarna: 'klarna',
  bancontact: PaymentMethod.bancontact,
}
