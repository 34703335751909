import { useTranslations } from 'next-intl'
import React, { ReactNode, useContext } from 'react'
import { ConfigContext } from '@/components/common/ConfigContext'
import { Heading3 } from '@/components/pages/checkout/common/Headings'
import classNames from 'classnames'

function getFormattedAmountValue(locale: string, amount: string, currency: string) {
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(Number(amount))
  } catch (e) {
    console.error(`Error formatting amount. Amount: "${amount}", Currency: "${currency}"`)

    return `${amount} ${currency}`
  }
}

export function PaymentOverview(props: {
  orderid: string
  firstname: string
  lastname: string
  email: string
  payment?: string
  amount: string
  currency: string
  customerId: string
}) {
  const t = useTranslations('CheckOut.PaymentOverview')
  const locale = useContext(ConfigContext)!.locale

  const formattedAmountValue = getFormattedAmountValue(locale, props.amount, props.currency)

  return (
    <>
      <Heading3>{t('paymentOverviewHeading')}</Heading3>
      <div className="w-full max-w-screen-sm sm:px-4">
        <dl className="grid grid-cols-[1fr,minmax(220px,1fr)] rounded-md border border-stone-300">
          <Row label={t('bookingNumberLabel')} value={props.orderid} />
          {props.firstname && props.lastname && (
            <Row
              label={t('nameLabel')}
              value={`${props.firstname || ''} ${props.lastname || ''}`}
            />
          )}
          {props.email && <Row label={t('emailLabel')} value={props.email} />}
          {props.customerId && <Row label={t('customerIdLabel')} value={props.customerId} />}
          {props.payment && <Row label={t('paymentTypeLabel')} value={props.payment} />}
          <Row label={t('paymentAmountLabel')} value={formattedAmountValue} bold />
        </dl>
      </div>
    </>
  )
}

function Row(props: { label: string; value: ReactNode | string; bold?: boolean }) {
  return (
    <>
      <dt className="min-w-0 border-b border-stone-300 p-4 last-of-type:border-0">{props.label}</dt>
      <dd
        className={classNames(
          'hyphens-auto border-b border-stone-300 p-4 text-right [overflow-wrap:anywhere] last-of-type:border-0',
          {
            'font-bold': props.bold,
            'text-stone-600': !props.bold,
          },
        )}
      >
        {props.value}
      </dd>
    </>
  )
}
